@import url('https://fonts.googleapis.com/css?family=Overpass');
@import url('https://fonts.googleapis.com/css?family=Lato');

/* GLOBAL OVERRIDES */
html{
    box-sizing: border-box;
    overflow-x: hidden;
}

*,
*::before,
*::after{
    box-sizing: inherit;
}

body {
    height: 100%;
    width: 100%;
    min-height: 90vh;
    margin: 0;
    overflow: hidden;
    background-color: #2f2f2f;
}


h1, h2, h3, h4, h5, h6{
    font-family: "Overpass", sans-serif;
    text-align: center;
    letter-spacing: 1px;
    text-shadow:0 0 1px transparent;
    color: rgba(247, 247, 255, 1);
}

p {
    font-family: "Lato", sans-serif;
    color:  #d7d7d8;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.8;
}

a{
    font-family: "Lato", sans-serif;
    text-decoration: none;
    color:rgba(247, 247, 255, 0.5);
    transition: all 0.2s ease-in-out;
}

a:hover{
    color:rgba(247, 247, 255, 1);
}

footer{
    text-align: center;
}

footer p {
    font-size: 1rem;
}

/* CUSTOM */
.wrapper{
    margin:auto;
    max-width: 800px;
    padding: 1em;
}

.content{
    margin:auto;
    line-height: 1.61803398875em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content p {
    text-align: center;
}

.animationAnchor {
    will-change: transform;
    display: inline-block;
}

.animatedButton{
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    margin: 1em;
    background-color: rgba(204, 24, 30, 1);
    outline: none;
    border:none;
    width:10rem;
    height: 2rem;
    background-position: center;
    transition: background 0.8s;
    user-select: none;
    box-shadow: 0px 2px 2px 2px rgba(0,0,0,.5);
}

.animatedButton:hover{
    transition: .2s ease all;
    background: rgba(204, 24, 30, .75) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, .2) 1%) center/15000%;
    cursor: pointer;
}

.animatedButton:active{
    background-size: 100%;
    transition: background 0s;
    transform: translateY(2px);
    box-shadow: 0 0 0 0  black;

}